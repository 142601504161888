<script>
import { BAvatar, BBadge } from 'bootstrap-vue';
import { formatDateToMonthShort } from '@core/utils/filter';
import useChat from './useChat';
import { phonePrettier } from '@/util/number';
import { isNull } from '@/util/inspect';
import { hasOwnProperty } from '@/util/object.util';

export default {
    components: {
        BAvatar,
        BBadge
    },
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        user: {
            type: Object,
            required: true
        },
        isChatContact: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        userPhone() {
            if (this.contactContext?.phone) {
                return `+${this.contactContext.phone}`;
            }

            return '';
        },
        contactContext() {
            if (!this.isChatContact) {
                return this.user;
            }
            return {
                ...this.user,
                ...this.user.user
            };
        },
        hasUserName() {
            let user = this.contactContext;

            // user = hasOwnProperty(this.contactContext, 'name')
            //     ? this.contactContext
            //     : this.contactContext.user;

            return user?.name && !isNull(user.name) && user.name.trim() !== '';
        },
        userName() {
            return this.contactContext?.name ?? this.contactContext.user.name;
        }
    },
    methods: {
        phonePrettier
    },
    setup() {
        const { resolveAvatarBadgeVariant } = useChat();
        return { formatDateToMonthShort, resolveAvatarBadgeVariant };
    }
};
</script>

<template>
    <component :is="tag" v-on="$listeners">
        <b-avatar
            size="42"
            :src="'contactContext.avatar'"
            :badge="isChatContact"
            class="badge-minimal"
            :badge-variant="resolveAvatarBadgeVariant(contactContext.status)"
            variant="primary"
        />
        <div class="chat-info flex-grow-1 d-flex flex-column justify-content-center">
            <h5
                class="mb-0 d-flex"
                :class="hasUserName ? 'flex-column' : 'align-items-center'"
                :style="hasUserName ? 'row-gap:0.4rem' : ''"
            >
                <span v-if="hasUserName" style="font-size: 1rem">
                    {{ userName }}
                </span>
                <span style="font-size: 14px"> {{ userPhone }} </span>
            </h5>
            <span class="card-text text-truncate" style="font-size: 12px">
                {{ contactContext.message }}
            </span>

            <!--            <p class="card-text text-truncate" v-if="isChatContact">-->
            <!--                {{ contactContext.message }} sultonov-->
            <!--            </p>-->
        </div>
        <div v-if="isChatContact" class="chat-meta text-nowrap">
            <b-badge
                pill
                v-if="contactContext.unread_count > 0"
                variant="primary"
                class="float-right"
            >
                {{ contactContext.unread_count }}
            </b-badge>
        </div>
    </component>
</template>

<style></style>
