<script>
import { hasOwnProperty } from '@/util/object.util';

export default {
    name: 'IconsGetter',
    props: {
        name: {
            type: String,
            required: true
        },
        size: {
            type: [String, Number],
            default: 20
        },
        sizeUnit: {
            type: [String, Number],
            default: 'px'
        },
        color: {
            type: String
        }
    },
    computed: {
        notHaveSlot() {
            return !hasOwnProperty(this.$slots, 'default');
        },
        iconStyle() {
            let style = `font-family: 'Material Symbols Rounded',serif;
          font-weight: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          direction: ltr;
          -webkit-font-feature-settings: 'liga';
          -webkit-font-smoothing: antialiased;
          `;
            if (this.color) {
                style += `color:${this.color}; fontSize: ${this.size}${this.sizeUnit}`;
            } else {
                style += `fontSize: ${this.size}${this.sizeUnit}`;
            }
            return style;
        }
    }
};
</script>

<template>
    <span class="material-symbols-rounded" :style="iconStyle">
        <slot name="default" />
        <template v-if="notHaveSlot">
            {{ name }}
        </template>
    </span>
</template>

<style lang="scss" scoped>
.icon-style {
    font-family: 'Material Symbols Rounded', serif;
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
</style>
