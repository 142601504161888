<script>
import clickOutside from '@/directives/click-outside';
import { MIcon } from '@/components/material-icons';
import { chatApi } from '@/services/chat/chat.service';
import { mapActions, mapGetters } from 'vuex';
import { keys } from '@/util/object.util';

import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
export default {
    components: {
        MIcon
    },
    directives: { clickOutside },
    emits: ['scroll-bottom'],
    props: {
        context: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showContextMenu: false,
            isBusy: false
        };
    },
    computed: {
        ...mapGetters('chatStore', ['getMerchantId']),
        chatId() {
            return this.$route.params.id;
        },
        isStaff() {
            return this.context.user_type === 'staff';
        },
        hasParent() {
            return this.context.parent && keys(this.context.parent).length > 0;
        },
        messageTypeFile() {
            return this.context?.file && keys(this.context.file).length > 0;
        },
        replyPermission() {
            return this.hasAccess('chat', 'update');
        },
        editPermission() {
            return this.hasAccess('chat', 'update');
        },
        deletePermission() {
            return this.hasAccess('chat', 'delete');
        }
    },
    mounted() {
        Fancybox.bind('[data-fancybox]');
    },
    methods: {
        ...mapActions('chatStore', ['removeMessage', 'setReplyContext', 'setEditContext']),
        isClientType(userType) {
            return userType === 'client';
        },
        openContextMenu() {
            this.showContextMenu = true;
        },
        closeContextMenu() {
            this.showContextMenu = false;
        },
        startLoading() {
            this.isBusy = true;
        },
        finishLoading() {
            this.isBusy = false;
        },
        getTime(t) {
            t = new Date(t);
            t.setHours(t.getHours() + 5);
            const h = t.getHours();
            const m = t.getMinutes();
            // const day = t.getDay();
            // const y = t.getFullYear();
            // const month = t.getMonth();
            // f(day) + '.' + f(month) + '.' + y
            let f = (x) => (x < 10 ? `0${x}` : x);

            return f(h) + ':' + f(m);
        },
        async deleteMessage() {
            this.startLoading();
            try {
                await chatApi
                    .removeMessage({
                        messageId: this.context.id,
                        chatId: this.chatId,
                        merchant_id: this.getMerchantId
                    })
                    .then(() => {
                        this.removeMessage({
                            chatId: this.chatId,
                            messageId: this.context.id
                        });
                        this.$nextTick(() => {
                            this.$emit('scroll-bottom');
                        });
                    });
            } finally {
                this.finishLoading();
            }
        },
        replyToMessage() {
            this.setReplyContext({
                replyData: this.context
            });
        },
        editMessageTrigger() {
            this.setEditContext({ editCtx: this.context });
            // this.deactivateEditField();
            // this.replyToMessage();
            // this.activateEditField();
        },
        async downloadImage() {
            if (this.context?.file?.path) {
                const link = document.createElement('a');
                link.href = this.context?.file?.path;
                link.download = this.context?.file?.name;
                link.target = '_blank';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        },
        getReplyMessage() {
            if (this.context?.parent?.file?.name) {
                return this.context.parent.file.name;
            }

            return this.context.parent.message;
        },
        scrollIntoReplyMessage(replyId) {
            const replyElm = document.getElementById('ch-m-ctn-' + replyId);
            if (replyElm) {
                replyElm.scrollIntoView();
            }
        },
        datesAreOnSameDay(first, second) {
            return (
                first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate()
            );
        },
        showMessageDate(idx) {
            if (idx !== 0) {
                const first = this.chatMessages.result[idx].created_at;
                const second = this.chatMessages.result[idx - 1].created_at;
                const isTheSameDate = this.datesAreOnSameDay(new Date(first), new Date(second));
                if (isTheSameDate) {
                    return false;
                }
            }

            return true;
        }
    }
};
</script>

<template>
    <div
        :id="'ch-m-ctn-' + context.id"
        class="chat"
        :class="{ 'chat-left': isClientType(context.user_type) }"
    >
        <div class="chat-avatar">
            <b-avatar
                size="36"
                class="avatar-border-2 box-shadow-1"
                :variant="isClientType(context.user_type) ? 'primary' : 'danger'"
            />
        </div>
        <div class="chat-body">
            <div
                :id="'message' + context.id"
                class="chat-content ol-chat-message-content"
                @click="showContextMenu = false"
                @contextmenu.prevent.stop="openContextMenu"
                v-click-outside="closeContextMenu"
            >
                <div
                    class="ch-message-parent"
                    v-if="hasParent"
                    @click="scrollIntoReplyMessage(context.parent.id)"
                >
                    <span style="white-space: pre-line">{{ getReplyMessage() }}</span>
                </div>
                <div>
                    <div v-if="messageTypeFile" class="cursor-pointer" style="padding-bottom: 14px">
                        <b-img-lazy
                            :src="context.file.path"
                            :alt="context.file.name"
                            :data-fancybox="context.file.path"
                            style="
                                width: 300px;
                                min-height: 200px;
                                height: auto;
                                object-fit: contain;
                            "
                        >
                        </b-img-lazy>
                        <p v-if="!context.message" style="padding: 0.3125rem; line-break: anywhere">
                            <span class="d-flex justify-content-end message-time">
                                {{ getTime(context.created_at) }}
                            </span>
                        </p>
                    </div>
                    <p
                        v-if="context.message"
                        :class="{ 'mt-1': messageTypeFile }"
                        style="padding: 0.3125rem; line-break: anywhere"
                    >
                        <span>
                            <span style="white-space: pre-line">{{ context.message }}</span>
                            <span class="message-time">
                                <b-spinner v-if="isBusy" small> </b-spinner>
                                <span v-else>
                                    {{ getTime(context.created_at) }}
                                </span>
                            </span>
                        </span>
                    </p>
                </div>
            </div>
            <b-popover
                v-if="showContextMenu"
                :disabled="showContextMenu"
                :show="showContextMenu"
                :target="'message' + context.id"
                triggers="hover"
                placement="bottomright"
                custom-class="popover-context-menu"
            >
                <div
                    v-if="replyPermission"
                    @click="replyToMessage"
                    class="context-menu-item d-flex align-items-center mb-1 cursor-pointer"
                >
                    <m-icon name="reply" />
                    <span class="context-menu-item-text"> Reply </span>
                </div>
                <div
                    v-if="isStaff && editPermission"
                    @click="editMessageTrigger"
                    class="context-menu-item d-flex align-items-center mb-1 cursor-pointer"
                >
                    <m-icon name="edit" />
                    <span class="context-menu-item-text"> Edit </span>
                </div>
                <div
                    v-if="isStaff && messageTypeFile"
                    @click="downloadImage"
                    class="context-menu-item d-flex align-items-center mb-1 cursor-pointer"
                >
                    <m-icon name="download" />
                    <span class="context-menu-item-text"> Download </span>
                </div>
                <div
                    v-if="isStaff && deletePermission"
                    @click="deleteMessage"
                    class="context-menu-item d-flex align-items-center mb-1 cursor-pointer"
                >
                    <m-icon name="delete" />
                    <span class="context-menu-item-text"> Delete </span>
                </div>
            </b-popover>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.context-menu-item {
    &-text {
        margin-left: 0.5rem;
        font-size: 1rem;
    }
}

.message-time {
    font-size: 0.75rem;
    white-space: nowrap;
    position: relative;
    top: 0.6rem;
    bottom: auto !important;
    float: right;
    line-height: 1.35;
    margin-left: 0.5rem;
    margin-right: -0.5rem;
    min-height: 1.5rem;
}

.ol-chat-message-content {
    min-width: 100px;
}

.ch-message-parent {
    border-left: 2px solid var(--white);
    font-size: 0.75rem;
    min-height: 2rem;
    padding-left: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}
</style>
