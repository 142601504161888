import { isToday } from '@/util';

export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

export function formatToPrice(rawPrice, decimalCount = 0) {
    if (!rawPrice) return 0;

    const dollarUSLocale = Intl.NumberFormat('en-US');
    const price = dollarUSLocale.format(parseInt(rawPrice));
    const result = price.toLocaleString().replace(/,/g, '  ');

    if (decimalCount) {
        const decimalValue = (rawPrice % 1).toFixed(decimalCount);
        if (decimalValue > 0) {
            return (result + decimalValue.slice(1)).replace('.', ',');
        }
    }

    return result;
}

export const title = (value, replacer = ' ') => {
    if (!value) return '';
    const str = value.toString();

    const arr = str.split(replacer);
    const capitalizedArray = [];
    arr.forEach((word) => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
        capitalizedArray.push(capitalized);
    });
    return capitalizedArray.join(' ');
};

export const avatarText = (value) => {
    if (!value) return '';
    const nameArray = value.split(' ');
    return nameArray.map((word) => word.charAt(0).toUpperCase()).join('');
};

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
    value,
    formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
    if (!value) return value;
    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
    const date = new Date(value);
    let formatting = { month: 'short', day: 'numeric' };

    if (toTimeForCurrentDay && isToday(date)) {
        formatting = { hour: 'numeric', minute: 'numeric' };
    }

    return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// Strip all the tags from markup and return plain text
export const filterTags = (value) => value.replace(/<\/?[^>]+(>|$)/g, '');
