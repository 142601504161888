<script>
// import ChatQueueMessage from '@/views/chat/ChatQueueMessage.vue';
import { isNUNEZ } from '@/util/inspect.util';
import { hasOwnProperty } from '@/util/object.util';
import { dateProperties, monthsNameList } from '@/util/date/calendar.util';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ChatMessageContent from '@/views/chat/ChatMessageContent.vue';
export default {
    name: 'ChatLog',
    components: {
        ChatMessageContent
        // ChatQueueMessage
    },
    props: {
        isFetching: {
            type: Boolean,
            default: false
        }
    },
    emits: ['scroll-bottom'],
    data() {
        return {
            showContextMenu: false
        };
    },
    computed: {
        ...mapState('chatStore', ['chatMessages']),
        ...mapGetters('chatStore', ['getMessagesById', 'hasQueueCtx', 'getChatResultByDate']),
        chatId() {
            return this.$route.params.id;
        },
        pagination() {
            if (this.hasMessages) {
                return this.getMessagesById(this.chatId).pagination;
            }
            return {
                current: 1,
                next: 0,
                perPage: 20,
                previous: 0,
                totalItem: 0,
                totalPage: 0
            };
        },
        hasMessages() {
            return (
                hasOwnProperty(this.getMessagesById(this.chatId), 'result') &&
                this.getMessagesById(this.chatId).result.length > 0
            );
        },
        isChatOpen() {
            return isNUNEZ(this.$route.params.id);
        }
    },
    watch: {
        '$route.params.id': {
            handler(pId) {
                this.setActiveChatId(pId);
                this.clearChatActiveMessages();
                this.clearReplyContext();
                this.chatMessageTrigger();
                this.setActiveUserInfo();
            },
            immediate: true
        }
    },
    mounted() {
        this.initMessagesWatcher();
    },
    methods: {
        ...mapMutations('chatStore', ['setActiveChatId']),
        ...mapActions('chatStore', [
            'chatMessagesReceiver',
            'refreshChatResult',
            'clearReplyContext',
            'clearActiveChatResult',
            'clearChatActiveMessages',
            'setActiveUserInfo',
            'initMessagesWatcher'
        ]),
        chatMessageTrigger() {
            if (this.isChatOpen) {
                if (!this.hasMessages) {
                    this.chatMessagesReceiver({
                        chatId: this.chatId,
                        perPage: this.pagination.perPage,
                        current: this.pagination.current
                    })
                        .then(() => {
                            this.$emit('scroll-bottom');
                        })
                        .catch(() => {
                            this.clearActiveChatResult();
                        });
                } else {
                    this.refreshChatResult({
                        chatId: this.chatId
                    });
                    this.$nextTick(() => {
                        this.$emit('scroll-bottom');
                    });
                }
            }
        },
        handleScrollBottom() {
            this.$emit('scroll-bottom');
        },
        datesAreOnSameDay(first, second) {
            return (
                first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate()
            );
        },
        showMessageDate(idx) {
            if (idx !== 0) {
                const first = this.chatMessages.result[idx].created_at;
                const second = this.chatMessages.result[idx - 1].created_at;
                const isTheSameDate = this.datesAreOnSameDay(new Date(first), new Date(second));
                if (isTheSameDate) {
                    return false;
                }
            }

            return true;
        },
        getMessageDay(t) {
            const { dayOfMonth, month, year, isCurrentYear } = dateProperties(t, 'string');
            const d = dayOfMonth < 10 ? `0${dayOfMonth}` : dayOfMonth;
            const m = monthsNameList[month];
            if (isCurrentYear) {
                return `${d} ${m}`;
            }
            return `${m} ${d},  ${year}`;
        }
    }
};
</script>

<template>
    <div class="chats">
        <div v-if="isFetching" class="text-center">
            <b-spinner variant="primary" label="Fetching" />
        </div>
        <div v-for="({ time, list }, index) in getChatResultByDate" :key="time + index">
            <span class="capitalize d-flex justify-content-center my-1">
                {{ getMessageDay(time) }}
            </span>
            <chat-message-content
                v-for="(m, index) in list"
                :key="m.id + String(index) + m.user_type"
                :context="m"
                @scroll-bottom="handleScrollBottom"
            />
        </div>
    </div>
</template>
