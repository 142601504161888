<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { useInfiniteScroll } from '@/composable/useInfiniteScroll';
import ChatLog from '@/views/chat/ChatLog.vue';
import ChatFormInput from '@/views/chat/ChatFormInput.vue';
import ChatEditAction from '@/views/chat/ChatEditAction.vue';
import ChatReplyAction from '@/views/chat/ChatReplyAction.vue';
import { isNull } from '@/util/inspect';
import { keys } from '@/util/object.util';
import { isNUNEZ } from '@/util/inspect.util';
import { phonePrettier } from '@/util/number';
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app';

export default {
    name: 'ChatMessages',
    components: {
        ChatLog,
        ChatFormInput,
        ChatEditAction,
        ChatReplyAction
    },
    emits: ['open-client-sidebar', 'open-left-sidebar'],
    data() {
        return {
            perfectScrollbarSettings: {
                maxScrollbarLength: 150
            },
            isChatContentFetching: false,
            chatMessagesContentId: 'chat-messages-content'
        };
    },
    computed: {
        ...mapState('chatStore', ['chatMessages', 'activeChatContact']),
        ...mapGetters('chatStore', ['getMessagesById', 'getActiveClientCtx', 'hasActiveClientCtx']),
        showChatEmptyArea() {
            const { currentBreakPoint } = useResponsiveAppLeftSidebarVisibility();
            if (
                currentBreakPoint.value === 'md' ||
                currentBreakPoint.value === 'sm' ||
                currentBreakPoint.value === 'xs'
            ) {
                return false;
            } else {
                return !isNUNEZ(this.$route.params.id) && !isNUNEZ(this.$route.query.client_id);
            }
        },
        isChatFetching() {
            return this.chatMessages.isFetching;
        },
        chatId() {
            return this.$route.params.id;
        },
        userName: {
            cache: false,
            get() {
                if (this.chatMessages?.user && keys(this.chatMessages.user).length > 0) {
                    const user = this.chatMessages.user;
                    if (user) {
                        if (user?.name && user.name.trim() !== '') {
                            return user.name;
                        }

                        return phonePrettier(user.phone);
                    }
                }
                return 'User';
            }
        },
        hasUserName() {
            if (!isNUNEZ(this.$route.params.id) && isNUNEZ(this.$route.query.client_id)) {
                return false;
            }

            const user = this.chatMessages.user;
            return user?.name && !isNull(user.name) && user.name.trim() !== '';
        },
        userPhone() {
            if (!isNUNEZ(this.$route.params.id) && isNUNEZ(this.$route.query.client_id)) {
                return `+${this.activeChatContact.phone}`;
            }

            if (this.chatMessages?.user?.phone) {
                return `+${this.chatMessages.user.phone}`;
            }

            return '';
        }
    },
    mounted() {
        const { mountInfiniteScroll: mChatMessagesInfiniteScroll } = useInfiniteScroll(
            this.fetchChatMessagesList,
            this.chatMessagesContentId
        );

        mChatMessagesInfiniteScroll({ reverse: true });
    },
    methods: {
        ...mapMutations('chatStore', ['scrollChatViewToBottom']),
        ...mapActions('chatStore', ['addMessagesByScroll']),
        phonePrettier,
        async fetchChatMessagesList() {
            if (!this.getMessagesById(this.chatId).complete) {
                this.isChatContentFetching = true;
                await this.addMessagesByScroll({
                    chatId: this.chatId
                }).finally(() => {
                    this.isChatContentFetching = false;
                });
            }
        },
        openClientContactSidebar() {
            this.$emit('open-client-sidebar');
        }
    }
};
</script>

<template>
    <div style="height: inherit">
        <section class="chat-app-window">
            <div v-if="showChatEmptyArea" class="start-chat-area">
                <div class="mb-1 start-chat-icon">
                    <feather-icon icon="MessageSquareIcon" size="56" />
                </div>
                <h4 class="sidebar-toggle start-chat-text">Start Conversation</h4>
            </div>
            <b-overlay
                v-else
                :show="isChatFetching"
                class="active-chat d-flex flex-column"
                rounded="sm"
                variant="transparent"
            >
                <!-- Chat Navbar -->
                <div class="chat-navbar">
                    <header class="chat-header">
                        <!-- Avatar & Name -->
                        <div class="d-flex align-items-center">
                            <!-- Toggle Icon -->
                            <div
                                class="sidebar-toggle d-block d-lg-none mr-1"
                                @click="$emit('open-left-sidebar')"
                            >
                                <feather-icon class="cursor-pointer" icon="MenuIcon" size="21" />
                            </div>
                            <div
                                class="d-flex align-items-center cursor-pointer"
                                @click="openClientContactSidebar"
                            >
                                <b-avatar
                                    badge
                                    class="mr-1 cursor-pointer badge-minimal"
                                    size="36"
                                    variant="primary"
                                />
                                <h5
                                    :class="
                                        hasUserName
                                            ? 'flex-column justify-content-center'
                                            : 'align-items-center'
                                    "
                                    :style="hasUserName ? 'font-size:14px;row-gap:0.4rem' : ''"
                                    class="mb-0 d-flex h-100"
                                >
                                    <span v-if="hasUserName"> {{ chatMessages.user.name }} </span>
                                    <span>
                                        {{ userPhone }}
                                    </span>
                                </h5>
                            </div>
                        </div>

                        <!-- Contact Actions -->
                        <div class="d-flex align-items-center">
                            <feather-icon
                                class="cursor-pointer d-sm-block d-none mr-1"
                                icon="PhoneCallIcon"
                                size="17"
                            />
                            <feather-icon
                                class="cursor-pointer d-sm-block d-none mr-1"
                                icon="VideoIcon"
                                size="17"
                            />
                            <feather-icon
                                class="cursor-pointer d-sm-block d-none mr-50"
                                icon="SearchIcon"
                                size="17"
                            />
                            <div class="dropdown">
                                <b-dropdown no-caret right toggle-class="p-0" variant="link">
                                    <template #button-content>
                                        <feather-icon
                                            class="align-middle text-body"
                                            icon="MoreVerticalIcon"
                                            size="17"
                                        />
                                    </template>
                                    <b-dropdown-item> View Contact</b-dropdown-item>
                                    <b-dropdown-item> Mute Notifications</b-dropdown-item>
                                    <b-dropdown-item> Block Contact</b-dropdown-item>
                                    <b-dropdown-item> Clear Chat</b-dropdown-item>
                                    <b-dropdown-item> Report</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </header>
                </div>

                <div
                    :id="chatMessages.chatMessagesContentId"
                    ref="refChatLogPS"
                    class="user-chats scroll-area"
                    style="overflow-y: scroll"
                >
                    <chat-log
                        ref="chatLog"
                        :is-fetching="isChatContentFetching"
                        @scroll-bottom="scrollChatViewToBottom"
                    />
                </div>

                <chat-edit-action />
                <chat-reply-action />
                <chat-form-input @scroll-bottom="scrollChatViewToBottom" />
            </b-overlay>
        </section>
    </div>
</template>

<style lang="scss" scoped></style>
