var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"inherit"}},[_c('div',{staticClass:"body-content-overlay",class:{
            show:
                _vm.shallShowUserProfileSidebar ||
                _vm.shallShowActiveChatContactSidebar ||
                _vm.mqShallShowLeftSidebar
        },on:{"click":function($event){_vm.mqShallShowLeftSidebar =
                _vm.shallShowActiveChatContactSidebar =
                _vm.shallShowUserProfileSidebar =
                    false}}}),_c('chat-messages',{on:{"open-client-sidebar":_vm.openClientDetails,"open-left-sidebar":_vm.openLeftSidebarHandler}}),(_vm.shallShowActiveChatContactSidebar)?_c('chat-active-chat-content-details-sidedbar',{on:{"close-details-sidebar":_vm.closeClientDetailsSidebar}}):_vm._e(),_c('portal',{attrs:{"to":"content-renderer-sidebar-left"}},[_c('chat-left-sidebar',{attrs:{"mq-shall-show-left-sidebar":_vm.mqShallShowLeftSidebar,"profile-user-data":_vm.profileUserData,"profile-user-minimal-data":_vm.profileUserDataMinimal,"shall-show-user-profile-sidebar":_vm.shallShowUserProfileSidebar},on:{"show-user-profile":_vm.showUserProfileSidebar,"open-chat":_vm.openChatOfContact,"close-left-sidebar":_vm.closeLeftSidebarHandler}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }