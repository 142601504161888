]
<script>
import { MIcon } from '@/components/material-icons';
import { mapActions, mapGetters } from 'vuex';
import { phonePrettier } from '@/util/number';
export default {
    name: 'ChatEditAction',
    components: {
        MIcon
    },
    computed: {
        ...mapGetters('chatStore', ['getEditContext', 'showEditContext'])
    },
    methods: {
        ...mapActions('chatStore', ['clearEditContext']),
        phonePrettier
    }
};
</script>

<template>
    <div class="reply-action-wrapper" v-if="showEditContext">
        <span class="d-flex align-items-center">
            <span class="reply-icon-wrapper">
                <m-icon name="edit" size="24" style="color: var(--primary)" />
            </span>
            <span class="reply-message-wrapper">
                <span class="reply-username">{{ phonePrettier(getEditContext.user.phone) }}</span>
                <span class="reply-message">{{ getEditContext.message }}</span>
            </span>
        </span>
        <span @click="clearEditContext" class="cursor-pointer">
            <m-icon name="close" size="24" />
        </span>
    </div>
</template>

<style lang="scss" scoped>
.reply-action-wrapper {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reply-message-wrapper {
    border-left: 2px solid var(--primary);
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;

    .reply-username {
        font-size: 0.9rem;
    }

    .reply-message {
        font-size: 0.75rem;
    }
}

.reply-icon-wrapper {
    display: flex;
    align-items: center;
    max-width: 5rem;
    height: 100%;
    padding-right: 1rem;
}
</style>
