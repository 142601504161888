<script>
import { mapState } from 'vuex';
import { BAvatar } from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { subscriberApi } from '@/services/subscriber/subscriber.service';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { formatToPrice } from '@/util/filter/filter.util';
import { phonePrettier } from '@/util/number';
import { showRequestErrorByToast } from '@/util/response.util';
export default {
    components: {
        // BSV
        BAvatar,

        // 3rd Party
        VuePerfectScrollbar,

        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent
    },
    emits: ['close-details-sidebar'],
    props: {
        // shallShowActiveChatContactSidebar: {
        //     type: Boolean,
        //     required: true
        // },
        contact: {
            type: Object,
            default: () => ({
                avatar: '',
                status: 'online',
                fullName: 'Sultonov Komron',
                role: 'admin',
                about: 'Software Engineer'
            })
        }
    },
    data() {
        return {
            perfectScrollbarSettings: {
                maxScrollbarLength: 150
            },
            personal: null,
            isFetching: false
        };
    },
    computed: {
        ...mapState('chatStore', ['chatMessages']),
        hasUserId() {
            return this.chatMessages.user?.id;
        }
    },
    created() {
        this.fetchClientDetails();
    },
    methods: {
        formatToPrice,
        phonePrettier,
        async fetchClientDetails() {
            if (this.hasUserId) {
                try {
                    this.startFetching();
                    const { data } = await subscriberApi.subscriberDetails({
                        subscriberId: this.chatMessages.user.id
                    });

                    this.personal = data;
                } catch (e) {
                    showRequestErrorByToast(e);
                    this.$emit('close-details-sidebar');
                } finally {
                    this.finishFetching();
                }
            }
        },
        startFetching() {
            this.isFetching = true;
        },
        finishFetching() {
            this.isFetching = false;
        },
        goMoreDetails() {
            const routeData = this.$router.resolve({
                name: 'subscribers Single Activities',
                params: {
                    id: this.chatMessages.user.id
                }
            });
            window.open(routeData.href, '_blank');
            // this.$router.push({
            //     name: 'subscribers Single Activities',
            //     params: {
            //         id: this.chatMessages.user.id
            //     }
            // });
        }
    }
};
</script>

<template>
    <div class="user-profile-sidebar show">
        <b-overlay :show="isFetching" rounded="sm">
            <!-- Header -->
            <header v-if="personal" class="user-profile-header">
                <span class="close-icon">
                    <feather-icon icon="XIcon" @click="$emit('close-details-sidebar')" />
                </span>

                <div class="header-profile-sidebar">
                    <b-avatar size="72" variant="primary" class="cursor-pointer badge-minimal" />
                    <h4 class="chat-user-name mt-2" style="font-size: 1rem">
                        {{ personal.full_name }}
                    </h4>
                </div>
            </header>

            <!-- User Details -->
            <vue-perfect-scrollbar
                :settings="perfectScrollbarSettings"
                class="user-profile-sidebar-area scroll-area d-flex flex-column justify-content-between"
            >
                <div>
                    <!-- About -->
                    <h6 class="section-label mb-1">Details</h6>

                    <!-- More Options -->
                    <div class="more-options">
                        <ul class="list-unstyled">
                            <li class="cursor-pointer mb-1">
                                Phone :
                                <span class="align-middle">
                                    {{ phonePrettier(this.chatMessages.user.phone) }}
                                </span>
                            </li>
                            <li class="cursor-pointer mb-1">
                                Tariff :
                                <span class="align-middle">{{ personal.tariff.name }}</span>
                            </li>
                            <li class="cursor-pointer mb-1">
                                Balance :
                                <span class="align-middle">
                                    {{ formatToPrice(personal.current_balance) }}
                                </span>
                            </li>
                            <li class="cursor-pointer mb-1">
                                Account status :
                                <span class="align-middle">
                                    {{ personal.account_status }}
                                </span>
                            </li>
                            <li class="cursor-pointer mb-1">
                                Status :
                                <span class="align-middle">
                                    {{ personal.status }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <b-button
                    class="cursor-pointer"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="goMoreDetails"
                >
                    <span class="mr-2"> More details </span>

                    <feather-icon icon="ExternalLinkIcon" />
                </b-button>
            </vue-perfect-scrollbar>
        </b-overlay>
    </div>
</template>

<style></style>
