<script>
import { MIcon } from '@/components/material-icons';
import { mapActions, mapGetters } from 'vuex';
import { phonePrettier } from '@/util/number';
export default {
    name: 'ChatReplyAction',
    components: {
        MIcon
    },
    computed: {
        ...mapGetters('chatStore', ['getReplyContext', 'showReplyContext'])
    },
    methods: {
        ...mapActions('chatStore', ['clearReplyContext']),
        phonePrettier
    }
};
</script>

<template>
    <div class="reply-action-wrapper" v-if="showReplyContext">
        <span class="d-flex align-items-center">
            <span class="reply-icon-wrapper">
                <m-icon name="reply" class="font-large-1" style="color: var(--primary)" />
            </span>
            <span class="reply-message-wrapper">
                <span class="reply-username">{{ phonePrettier(getReplyContext.user.phone) }}</span>
                <span class="reply-message">{{ getReplyContext.message }}</span>
            </span>
        </span>
        <span @click="clearReplyContext" class="cursor-pointer">
            <m-icon name="close" size="24" />
        </span>
    </div>
</template>

<style lang="scss" scoped>
.reply-action-wrapper {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reply-message-wrapper {
    border-left: 2px solid var(--primary);
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;

    .reply-username {
        font-size: 0.9rem;
    }

    .reply-message {
        font-size: 0.75rem;
    }
}

.reply-icon-wrapper {
    display: flex;
    align-items: center;
    max-width: 5rem;
    height: 100%;
    padding-right: 1rem;
}
</style>
