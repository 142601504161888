<script>
import store from '@/store';
import ChatContact from './ChatContact.vue';
import UserProfileSidebar from './UserProfileSidebar.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { useInfiniteScroll } from '@/composable/useInfiniteScroll';
import { computed, onMounted } from '@vue/composition-api';
import { BAvatar, BInputGroup, BInputGroupPrepend, BFormInput } from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';
import { dateProperties } from '@/util/date/calendar.util';
// import { mapState } from 'vuex';
// import { ROOT, ROUTE, ROUTER } from '@/constants/names';
// import { sessionStorageController } from '@/util/localstorage.util';

export default {
    components: {
        // BSV
        BAvatar,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        VuePerfectScrollbar,

        // SFC
        ChatContact,
        UserProfileSidebar
    },
    emits: ['close-left-sidebar'],
    props: {
        shallShowUserProfileSidebar: {
            type: Boolean,
            required: true
        },
        profileUserData: {
            type: Object,
            required: true
        },
        profileUserMinimalData: {
            type: Object,
            required: true
        },
        mqShallShowLeftSidebar: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            tabIndex: 0,
            perfectScrollbarSettings: {
                maxScrollbarLength: 150
            }
        };
    },
    computed: {
        ...mapState('chatStore', ['search', 'activeTab', 'chats']),
        getChatListByLatest() {
            const result = this.chats.result.slice();
            if (result.length) {
                return result.sort((a, b) => {
                    const { time: aTime } = dateProperties(a['updated_at'], 'string');
                    const { time: bTime } = dateProperties(b['updated_at'], 'string');
                    return b.unread_count - a.unread_count || bTime - aTime;
                });
            }
            return [];
        }
    },
    watch: {
        'search.query'(searchQuery) {
            this.tabIndex = 1;

            if (searchQuery.length > 3 || !searchQuery.length) {
                this.contactReceiver({});
            }
        }
        // tabIndex: {
        //     handler(activeIdx) {
        //         this.$router.push({
        //             query: {
        //                 active_tab: activeIdx
        //             }
        //         });
        //     },
        //     immediate: true
        // }
    },
    mounted() {
        const chatTab = document.querySelector('#ol-chat-tab');
        const listItems = chatTab.getElementsByTagName('ul');
        listItems[0].style.boxShadow = '0 2px 2px rgba(0,0,0,0.4)';
        const sidebarContent = document.getElementById('ol-chat-sidebar-content');
        const searchContent = document.getElementById('ol-sidebar-search-place');
        const contactsElm = document.getElementById('chat-users-contact-list');
        const chatsListElm = document.getElementById('chat-users-chats');
        const sidebarHeight = sidebarContent.getBoundingClientRect().height;
        const searchHeight = searchContent.getBoundingClientRect().height;
        const deltaHeight = sidebarHeight - searchHeight;
        contactsElm.style.height = deltaHeight - 80 + 'px';
        chatsListElm.style.height = deltaHeight - 80 + 'px';
    },
    methods: {
        ...mapActions('chatStore', ['setTabIndex', 'contactReceiver']),
        isActiveChat(contact) {
            const paramsId = this.$route.params?.id?.toString();
            return (
                paramsId &&
                (paramsId === contact.id.toString() || paramsId === contact.chat_id?.toString())
            );
        },
        isActiveContact(contact) {
            const paramsId = this.$route.params?.id?.toString();
            const clientId = this.$route.query?.client_id?.toString();

            return (
                (contact.chat_id && contact.chat_id.toString() === paramsId) ||
                contact.id.toString() === clientId
            );
        }
    },

    setup() {
        Promise.allSettled([
            store.dispatch('chatStore/chatReceiver'),
            store.dispatch('chatStore/contactReceiver')
        ]);

        // const route = context[ROOT][ROUTE];
        // const router = context[ROOT][ROUTER];
        const chats = computed(() => store.state.chatStore.chats);
        const contacts = computed(() => store.state.chatStore.contacts);

        async function fetchContactsList() {
            if (!contacts.value.complete) {
                await store.dispatch('chatStore/contactReceiver', {
                    infinite: true,
                    perPage: contacts.value.pagination.perPage,
                    current: contacts.value.pagination.current + 1
                });
            }
        }

        async function fetchChatsList() {
            if (!chats.value.isFetching) {
                await store.dispatch('chatStore/chatReceiver', {
                    infinite: true,
                    perPage: chats.value.pagination.perPage,
                    current: chats.value.pagination.current + 1
                });
            }
        }

        const { mountInfiniteScroll: mContactsInfiniteScroll } = useInfiniteScroll(
            fetchContactsList,
            'chat-users-contact-list'
        );

        const { mountInfiniteScroll: mChatsInfiniteScroll } = useInfiniteScroll(
            fetchChatsList,
            'chat-users-chats'
        );

        onMounted(() => {
            mChatsInfiniteScroll({ reverse: false });
            mContactsInfiniteScroll({ reverse: false });
        });

        return {
            contacts
        };
    }
};
</script>

<template>
    <div class="sidebar-left">
        <div class="sidebar">
            <!-- Logged In User Profile Sidebar -->
            <user-profile-sidebar
                :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
                :profile-user-data="profileUserData"
                @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
            />

            <!-- Sidebar Content -->
            <div
                id="ol-chat-sidebar-content"
                class="sidebar-content"
                :class="{ show: mqShallShowLeftSidebar }"
            >
                <!-- Sidebar close icon -->
                <span class="sidebar-close-icon">
                    <feather-icon
                        icon="XIcon"
                        size="16"
                        @click="$emit('close-left-sidebar', false)"
                    />
                </span>

                <!-- Header -->
                <div id="ol-sidebar-search-place" class="chat-fixed-search">
                    <div class="d-flex align-items-center w-100">
                        <div class="sidebar-profile-toggle">
                            <b-avatar
                                size="42"
                                class="cursor-pointer badge-minimal avatar-border-2"
                                :src="profileUserMinimalData.avatar"
                                badge
                                variant="danger"
                                badge-variant="success"
                                @click="$emit('show-user-profile')"
                            />
                        </div>
                        <!-- Search -->
                        <b-input-group class="input-group-merge ml-1 w-100 round">
                            <b-input-group-prepend is-text>
                                <feather-icon icon="SearchIcon" class="text-muted" />
                            </b-input-group-prepend>
                            <b-form-input v-model="search.query" placeholder="Search..." />
                        </b-input-group>
                    </div>
                </div>

                <!-- ScrollArea: Chat & Contacts -->
                <b-tabs
                    v-model="tabIndex"
                    id="ol-chat-tab"
                    :nav-class="['ol-chat-navigation']"
                    :nav-wrapper-class="['ol-chat-nav-wrapper']"
                >
                    <b-tab title="Chats">
                        <div class="chat-user-list-wrapper list-group scroll-area">
                            <vue-perfect-scrollbar
                                id="chat-users-chats"
                                :settings="perfectScrollbarSettings"
                                class="chat-users-list chat-list media-list"
                            >
                                <chat-contact
                                    is-chat-contact
                                    tag="li"
                                    v-for="contact in getChatListByLatest"
                                    :key="contact.id"
                                    :user="contact"
                                    :class="{
                                        active: isActiveChat(contact)
                                    }"
                                    @click="
                                        $emit('open-chat', {
                                            by: 'chat',
                                            contact
                                        })
                                    "
                                />
                            </vue-perfect-scrollbar>
                            <div v-if="chats.isFetching" class="d-flex justify-content-center my-1">
                                <b-spinner label="Loading..." variant="primary" />
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Contacts">
                        <div class="chat-user-list-wrapper list-group scroll-area">
                            <vue-perfect-scrollbar
                                :settings="perfectScrollbarSettings"
                                class="chat-users-list contact-list media-list overflow-auto"
                                id="chat-users-contact-list"
                            >
                                <chat-contact
                                    v-for="contact in contacts.result"
                                    :key="contact.id"
                                    :user="contact"
                                    tag="li"
                                    :class="{
                                        active: isActiveContact(contact)
                                    }"
                                    @click="
                                        $emit('open-chat', {
                                            by: 'contact',
                                            contact
                                        })
                                    "
                                />
                            </vue-perfect-scrollbar>
                            <div v-if="contacts.isFetching" class="d-flex justify-content-center">
                                <b-spinner label="Loading..." variant="primary" />
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>
