<script>
import { isEmptyObject, isNUNEZ } from '@/util/inspect.util';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { uploadApi } from '@/services/upload/upload.service';
import { hasOwnProperty, keys } from '@/util/object.util';
import { chatApi } from '@/services/chat/chat.service';

export default {
    name: 'ChatInputForm',
    emits: ['scroll-bottom'],
    data() {
        return {
            chatInputMessage: '',
            isSending: false
        };
    },
    computed: {
        ...mapGetters('chatStore', [
            'getReplyContext',
            'getEditContext',
            'showEditContext',
            'getMerchantId'
        ])
    },
    watch: {
        getEditContext: {
            handler(editCtx) {
                if (editCtx?.file && keys(editCtx.file).length && !editCtx.message) {
                    this.onPickFile();
                }

                if (!isEmptyObject(editCtx) && isNUNEZ(editCtx.message)) {
                    this.chatInputMessage = editCtx.message;
                } else {
                    this.chatInputMessage = '';
                }
            },
            deep: true
        },
        getReplyContext: {
            handler(replyCtx) {
                const hasReplyCtx = keys(replyCtx).length > 0;
                if (hasReplyCtx) {
                    this.$refs.chatInputField.focus();
                }
            },
            deep: true
        }
    },
    methods: {
        ...mapMutations('chatStore', ['setClientChatId', 'addNewChat']),
        ...mapActions('chatStore', [
            'sendMessage',
            'clearReplyContext',
            'updateMessage',
            'cancelRequest'
        ]),
        async createChatBeforeSendingMessage() {
            const { params, query } = this.$route;
            if (hasOwnProperty(params, 'id') && isNUNEZ(params.id)) {
                return true;
            } else if (hasOwnProperty(query, 'client_id')) {
                const {
                    data: { result }
                } = await chatApi.getChatIdByClientId({
                    body: {
                        merchant_id: this.getMerchantId,
                        client_id: query.client_id
                    }
                });

                this.addNewChat({ result });

                this.setClientChatId({
                    chatId: result.id,
                    clientId: result.user.id
                });

                return await this.$router
                    .push({
                        params: {
                            id: result.id
                        },
                        query: {
                            ...query,
                            client_id: undefined
                        }
                    })
                    .then(() => true)
                    .catch(() => false);
            }
        },
        async applyAction() {
            try {
                if (isNUNEZ(this.chatInputMessage) && !this.isSending) {
                    this.startSending();
                    const message = this.chatInputMessage;
                    this.clearChatInputMessage();

                    if (this.showEditContext) {
                        await this.editMessageTrigger({ message });
                    } else {
                        await this.sendMessageTrigger({ message });
                    }
                }
            } catch (e) {
                this.cancelRequest();
            } finally {
                this.finishSending();
                this.clearChatInputMessage();
            }
        },
        startSending() {
            this.isSending = true;
        },
        finishSending() {
            this.isSending = false;
        },
        async editMessageTrigger({ message }) {
            if (!this.hasAccess('chat', 'edit')) {
                return;
            }

            await this.updateMessage({
                message,
                chatId: this.$route.params.id
            });
        },
        async sendMessageTrigger({ message }) {
            if (!this.hasAccess('chat', 'create')) {
                return;
            }

            const allowToSend = await this.createChatBeforeSendingMessage();
            if (allowToSend) {
                await this.sendMessage({
                    message,
                    chatId: this.$route.params.id
                }).then(() => {
                    this.$emit('scroll-bottom');
                });
            }
            this.clearReplyContext();
        },
        onPickFile() {
            this.$refs.fileInput.click();
        },
        async onFilePicked(event) {
            try {
                this.startSending();
                const allowToSend = await this.createChatBeforeSendingMessage();
                if (allowToSend) {
                    const files = event.target.files;
                    const f = new FormData();
                    f.append('merchant_id', `${this.getMerchantId}`);
                    f.append('file', files[0]);
                    const response = await uploadApi.uploadFile(f);
                    if (this.showEditContext) {
                        await this.updateMessage({
                            chatId: this.$route.params.id,
                            uploadId: response.data.result.id
                        });
                    } else {
                        await this.sendMessage({
                            chatId: this.$route.params.id,
                            uploadId: response.data.result.id,
                            uploadName: response.data.result.name
                        }).then(() => {
                            this.$nextTick(() => {
                                this.$emit('scroll-bottom');
                            });
                        });
                    }
                }
            } catch (e) {
                this.cancelRequest();
            } finally {
                event.target.value = '';
                this.finishSending();
            }
        },

        clearChatInputMessage() {
            this.chatInputMessage = '';
        }
    }
};
</script>

<template>
    <b-form class="chat-app-form" @submit.prevent="applyAction">
        <input
            ref="fileInput"
            accept="image/*"
            style="display: none"
            type="file"
            @change="onFilePicked"
        />
        <!--        ,application/pdf-->
        <span
            class="m-2 cursor-pointer flex align-items-center justify-content-center"
            @click="onPickFile"
        >
            <feather-icon icon="PaperclipIcon" size="20" />
        </span>
        <b-input-group class="input-group-merge form-send-message mr-1 p-relative">
            <textarea
                @keyup.shift.enter="applyAction"
                v-model="chatInputMessage"
                class="w-100"
                style="
                    resize: none;
                    height: 76px;
                    border: 1px solid #d8d6de;
                    border-radius: 0.357rem;
                    padding: 7px 14px;
                "
                ref="chatInputField"
                placeholder="Enter your message"
            ></textarea>
        </b-input-group>
        <b-button class="mr-1" type="submit" variant="primary">
            <b-spinner v-if="isSending" small />
            <span v-else>Send</span>
        </b-button>
    </b-form>
</template>

<style lang="scss" scoped></style>
