export default function useChat() {
    const resolveAvatarBadgeVariant = (status) => {
        if (status === 'online') return 'success';
        if (status === 'busy') return 'danger';
        if (status === 'offline') return 'warning';
        return 'secondary';
    };

    return {
        resolveAvatarBadgeVariant
    };
}
