import Core from '@/services/axios/core.axios';

class UploadService extends Core {
    constructor() {
        super({});
    }

    uploadFile(body) {
        return this.post('uploads/chats', body);
    }
}

export const uploadApi = new UploadService();
