<script>
import store from '@/store';
import { onUnmounted, ref } from '@vue/composition-api';
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app';
import useChat from './useChat';
import chatStoreModule from './chatStoreModule';
import { $themeBreakpoints } from '@themeConfig';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

import ChatLeftSidebar from './ChatLeftSidebar.vue';
import ChatMessages from '@/views/chat/ChatMessages.vue';
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue';
import { isNUNEZ } from '@/util/inspect.util';
// import { chatApi } from '@/services/chat/chat.service';
// import { dateProperties } from '@/util/date/calendar.util';

export default {
    components: {
        // SFC
        ChatMessages,
        ChatLeftSidebar,
        ChatActiveChatContentDetailsSidedbar
    },
    computed: {
        ...mapState('chatStore', {
            contactsState: 'contacts',
            chatsState: 'chats',
            tabIndex: 'activeTab'
        }),
        ...mapGetters('chatStore', ['getMerchantId'])
    },

    beforeRouteLeave(to, from, next) {
        this.clearChatStore();
        next();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.chatsPermissionTrigger({
                property: 'update',
                value: true
            });

            vm.messagesPermissionTrigger({
                property: 'update',
                value: true
            });
        });
    },
    setup() {
        const CHAT_APP_STORE_MODULE_NAME = 'app-chat';

        // Register module
        if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME))
            store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CHAT_APP_STORE_MODULE_NAME))
                store.unregisterModule(CHAT_APP_STORE_MODULE_NAME);
        });

        const { resolveAvatarBadgeVariant } = useChat();

        // ------------------------------------------------
        // Chats & Contacts
        // ------------------------------------------------
        const chatsContacts = ref([]);
        const contacts = ref([]);

        const perfectScrollbarSettings = {
            maxScrollbarLength: 150
        };

        // User Profile Sidebar
        // ? Will contain all details of profile user (e.g. settings, about etc.)
        const profileUserData = ref({});
        // ? Will contain id, name and avatar & status
        const profileUserDataMinimal = ref({});

        const shallShowUserProfileSidebar = ref(false);
        const showUserProfileSidebar = () => {
            store.dispatch('app-chat/getProfileUser').then((response) => {
                profileUserData.value = response.data;
                shallShowUserProfileSidebar.value = true;
            });
        };

        // Active Chat Contact Details
        const shallShowActiveChatContactSidebar = ref(false);

        // UI + SM Devices
        // Left Sidebar Responsiveness
        const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
        const startConversation = () => {
            if (store.state.app.windowWidth < $themeBreakpoints.lg) {
                mqShallShowLeftSidebar.value = true;
            }
        };

        function openClientDetails() {
            this.$router.push({
                name: 'subscriber-new-logger',
                params: { id: store.state.chatStore.chatMessages.user.id }
            });
            // shallShowActiveChatContactSidebar.value = true;
        }

        function closeClientDetailsSidebar() {
            shallShowActiveChatContactSidebar.value = false;
        }

        function closeLeftSidebarHandler() {
            mqShallShowLeftSidebar.value = false;
        }

        function openLeftSidebarHandler() {
            mqShallShowLeftSidebar.value = true;
        }

        return {
            // Filters
            // formatDate,
            // useChat
            resolveAvatarBadgeVariant,
            closeLeftSidebarHandler,
            openLeftSidebarHandler,

            openClientDetails,
            closeClientDetailsSidebar,

            // Chat & Contacts
            chatsContacts,
            contacts,

            // Profile User Minimal Data
            profileUserDataMinimal,

            // User Profile Sidebar
            profileUserData,
            shallShowUserProfileSidebar,
            showUserProfileSidebar,

            // Active Chat Contact Details
            shallShowActiveChatContactSidebar,

            // UI
            perfectScrollbarSettings,

            // UI + SM Devices
            startConversation,
            mqShallShowLeftSidebar
        };
    },
    mounted() {
        setTimeout(() => {
            this.initChatWatcher();
        }, 3000);
    },
    methods: {
        ...mapMutations('chatStore', [
            'messagesStartFetching',
            'messagesFinishFetching',
            'clearChatUnreadCount',
            'clearContactUnreadCount',
            'initActiveClient'
        ]),
        ...mapActions('chatStore', [
            'setActiveClient',
            'clearChatStore',
            'initChatWatcher',
            'chatsPermissionTrigger',
            'messagesPermissionTrigger'
        ]),
        async openChatOfContact({ by, contact }) {
            if (by === 'contact') {
                try {
                    if (isNUNEZ(contact.chat_id)) {
                        this.clearChatUnreadCount({ id: contact.chat_id });

                        this.clearContactUnreadCount({
                            id: contact.chat_id
                        });

                        await this.$router.push({
                            params: {
                                id: contact.chat_id
                            },
                            query: {
                                client_id: undefined
                            }
                        });
                    } else if (this.$route.query?.client_id?.toString() !== contact.id.toString()) {
                        this.initActiveClient(contact);

                        await this.$router.push({
                            params: {
                                id: undefined
                            },
                            query: {
                                client_id: contact.id
                            }
                        });
                    }

                    // this.messagesStartFetching();
                    // const {
                    //     data: { result }
                    // } = await chatApi.getChatIdByClientId({
                    //     body: {
                    //         merchant_id: this.getMerchantId,
                    //         client_id: contact.id
                    //     }
                    // });
                    //
                    // this.openChatBySidebarNav(result);
                } finally {
                    // this.messagesFinishFetching();
                }
            } else {
                this.clearChatUnreadCount({ id: contact.id });
                this.openChatBySidebarNav(contact);
            }
        },
        openChatBySidebarNav(contact) {
            if (contact.id.toString() !== this.$route.params.id?.toString()) {
                this.setActiveClient({
                    clientCtx: contact
                });

                this.$router.push({
                    params: {
                        id: contact.id
                    }
                });
            }
            this.closeLeftSidebarHandler();
        }
    }
};
</script>

<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <div style="height: inherit">
        <div
            :class="{
                show:
                    shallShowUserProfileSidebar ||
                    shallShowActiveChatContactSidebar ||
                    mqShallShowLeftSidebar
            }"
            class="body-content-overlay"
            @click="
                mqShallShowLeftSidebar =
                    shallShowActiveChatContactSidebar =
                    shallShowUserProfileSidebar =
                        false
            "
        />

        <!-- Chat Content -->
        <chat-messages
            @open-client-sidebar="openClientDetails"
            @open-left-sidebar="openLeftSidebarHandler"
        />
        <!--        @open-client-sidebar="shallShowActiveChatContactSidebar = true"-->

        <!-- Active Chat Contact Details Sidebar -->
        <chat-active-chat-content-details-sidedbar
            v-if="shallShowActiveChatContactSidebar"
            @close-details-sidebar="closeClientDetailsSidebar"
        />

        <!-- Sidebar -->
        <portal to="content-renderer-sidebar-left">
            <chat-left-sidebar
                :mq-shall-show-left-sidebar="mqShallShowLeftSidebar"
                :profile-user-data="profileUserData"
                :profile-user-minimal-data="profileUserDataMinimal"
                :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
                @show-user-profile="showUserProfileSidebar"
                @open-chat="openChatOfContact"
                @close-left-sidebar="closeLeftSidebarHandler"
            />
        </portal>
    </div>
</template>

<style lang="scss" scoped></style>

<style lang="scss">
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';
</style>
